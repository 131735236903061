import {
  ArrowUturnLeftIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { profileSelector } from '@hints/client';

import userLogo from '../../../../assets/avatar_1.svg';
import logo from '../../../../assets/logo-circle.svg';
import Form from '../components/message-widgets/Form';
import StreamingSteps from '../components/message-widgets/StreamingSteps';
import { useAppSelector } from '../hooks';

interface MessageProps {
  sender: 'user' | 'gpt';
  content: string;
  onReply: (content: string) => void;
}

const Message: React.FC<MessageProps> = ({ sender, content, onReply }) => {
  const user = useAppSelector(profileSelector);
  const [icon, setIcon] = useState<'clipboard' | 'check'>('clipboard');
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [messageStyle, setMessageStyle] = useState<'plain' | 'highlight'>(
    'plain',
  );

  const transformLinksToMarkdown = (text: string) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.replace(urlRegex, (url) => `[Link](${url})`);
  };

  const markdownContent = transformLinksToMarkdown(content);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(content).then(() => {
      setIcon('check');
    });
  };

  const handleReply = () => {
    onReply(content);
  };

  useEffect(() => {
    if (icon === 'check') {
      const timeout = setTimeout(() => {
        setIcon('clipboard');
      }, 1000);

      return () => clearTimeout(timeout);
    }
    return;
  }, [icon]);

  function renderContent() {
    return (
      <ReactMarkdown
        className={`markdown w-full prose-sm ${
          messageStyle === 'highlight'
            ? sender === 'user'
              ? 'text-white'
              : 'text-gray-800'
            : 'text-gray-700 ml-10'
        }`}
        children={markdownContent}
        linkTarget="_blank"
      />
    );
  }

  return (
    <article
      className={`relative prose prose-h1:text-base prose-h1:font-semibold prose-h2:mt-0 prose-h2:text-base prose-h2:font-semibold prose-h3:text-base prose-h3:font-semibold prose-a:text-indigo-500 max-w-[80%] lg:max-w-[50%] mb-2 px-3 py-2 flex flex-col rounded-2xl group ${
        messageStyle === 'highlight'
          ? sender === 'user'
            ? 'text-white bg-indigo-500 rounded-xl relative p-5 mb-3 max-w-xs break-words self-end'
            : 'text-black bg-indigo-50 self-start rounded-xl relative p-5 mb-3 max-w-xs break-words'
          : 'text-gray-800 bg-transparent self-start relative p-5 px-5 mb-3 break-words max-w-none'
      } ${content === 'form' && 'max-w-[100%] lg:max-w-[100%] w-full'} `}
    >
      {messageStyle !== 'highlight' && sender === 'user' ? (
        <div className="w-full flex space-x-3 items-center -mb-7">
          <img
            className="h-7 w-7 rounded-full bg-gray-30"
            src={user.photoUrl || userLogo}
            alt="user avatar"
          />
          <span className="font-semibold text-gray-900 mb-0">You</span>
        </div>
      ) : (
        <div className="w-full flex space-x-3 items-center -mb-7">
          <img className="h-7 w-7 rounded-full bg-gray-30" src={logo} alt="" />
          <span className="font-semibold text-gray-900 mb-0">
            CRM Assistant
          </span>
        </div>
      )}
      {renderContent()}
      {sender !== 'user' ? (
        <>
          {messageStyle === 'highlight' && (
            <>
              <div
                className="absolute bg-indigo-50 rounded-br-lg"
                style={{
                  left: '-7px',
                  bottom: '0',
                  width: '20px',
                  height: '25px',
                }}
              ></div>
              <div
                className="absolute bg-white rounded-br-lg"
                style={{
                  left: '-26px',
                  bottom: '0',
                  width: '26px',
                  height: '25px',
                }}
              ></div>
            </>
          )}
          {content !== 'Got it! Processing...' && (
            <div
              className={`${
                messageStyle === 'plain' && 'ml-9'
              } w-full py-1 flex justify-start items-center`}
            >
              <button
                onClick={() => setIsLiked(!isLiked)}
                className={`p-1.5 hover:bg-gray-300 ${
                  isLiked ? 'text-indigo-600' : 'text-gray-400'
                } rounded-md transition-all duration-100 ease-in-out`}
              >
                <HandThumbUpIcon className="w-4 h-4" />
              </button>
              <button
                onClick={() => setIsDisliked(!isDisliked)}
                className={`p-1.5 hover:bg-gray-300 ${
                  isDisliked ? 'text-red-400' : 'text-gray-400'
                } rounded-md transition-all duration-100 ease-in-out`}
              >
                <HandThumbDownIcon className="w-4 h-4" />
              </button>
              <button
                className="p-1.5 text-gray-400 hover:bg-gray-300 rounded-md transition-all duration-100 ease-in-out"
                onClick={handleCopyToClipboard}
              >
                {icon === 'clipboard' ? (
                  <ClipboardDocumentIcon className="w-4 h-4" />
                ) : (
                  <CheckCircleIcon className="w-4 h-4 text-teal-500" />
                )}
              </button>
              <button
                className="p-1.5 text-gray-400 hover:bg-gray-300 rounded-md transition-all duration-100 ease-in-out"
                onClick={handleReply}
              >
                <ArrowUturnLeftIcon className="w-4 h-4" />
              </button>
            </div>
          )}
        </>
      ) : (
        messageStyle === 'highlight' && (
          <>
            <div
              className="absolute bg-indigo-500 rounded-bl-lg"
              style={{
                right: '-7px',
                bottom: '0',
                width: '20px',
                height: '25px',
              }}
            ></div>
            <div
              className="absolute bg-white rounded-bl-lg"
              style={{
                right: '-26px',
                bottom: '0',
                width: '26px',
                height: '25px',
              }}
            ></div>
          </>
        )
      )}
    </article>
  );
};

export default Message;
