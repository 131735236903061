import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import { CRMIntegration, isSalesDestination } from '@hints/types';

import { ampli } from '../../../ampli';
import { useAllOutputs } from '../QuickIntegration/hooks/useOutputMetadata';

export const DestinationCard = ({
  title,
  logo,
  onIntegrationClick,
}: {
  title: string;
  logo: string;
  onIntegrationClick: () => void;
}) => (
  <li
    key={title}
    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-gray-300 border"
  >
    <div className="flex w-full items-center justify-between space-x-6 p-6">
      <div className="flex-1 w-full ">
        <div className="flex items-center space-x-3">
          <h3 className="truncate font-medium text-gray-900">{title}</h3>
          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            AI ready
          </span>
        </div>
      </div>
      <img className="h-10 w-10 flex-shrink-0" src={logo} alt="" />
    </div>
    <div>
      <div className="-mt-px flex divide-x divide-gray-200">
        <div className="-ml-px flex w-0 flex-1">
          <button
            type="button"
            onClick={onIntegrationClick}
            className="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-b-lg py-4 text-sm font-semibold text-gray-800 hover:bg-gray-100 transition-all duration-300 ease-in-out"
          >
            <PlusCircleIcon
              className="h-5 w-5 text-gray-800"
              aria-hidden="true"
            />
            Connect
          </button>
        </div>
      </div>
    </div>
  </li>
);

export const ChooseCopilotDestination = () => {
  const navigate = useNavigate();
  const allOutputs = useAllOutputs();
  const salesDestinations = allOutputs.filter((dest) =>
    isSalesDestination(dest.key),
  );

  return (
    <div className="relative isolate px-6 lg:px-8">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="text-center">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 mb-8">
            Choose CRM to update after calls
          </h1>
          <ul className="w-full grid mt-4 grid-cols-1 gap-6 sm:grid-cols-2">
            {salesDestinations.map(({ title, logoUrl, key }, i) => (
              <DestinationCard
                key={title}
                title={title}
                logo={logoUrl}
                onIntegrationClick={() => {
                  ampli.destinationSelected({
                    destination: key as CRMIntegration['destination'],
                    product: 'crmchat',
                  });
                  navigate(
                    `/connect/any/${salesDestinations[i].key}?destinationSelected=1`,
                  );
                }}
              />
            ))}
          </ul>
          {/* <div className="hidden sm:mt-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Have questions?{' '}
              <a
                href="https://calendly.com/hints/45min"
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-indigo-600"
                onClick={() => {
                  ampli.integrationSetupOnboardingCallClicked({
                    product: 'crmchat',
                  });
                }}
              >
                <span className="absolute inset-0" aria-hidden="true" />
                Book a call with our AI specialist
                <span aria-hidden="true" className="ml-1">
                  &rarr;
                </span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
};
