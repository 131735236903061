import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { createElement, useEffect, useState } from 'react';
import { animated, useTrail } from 'react-spring';

import {
  CRMIntegration,
  IntegrationDestination,
  IntegrationSource,
  isSalesDestination,
} from '@hints/types';

import { ampli } from '../../../../ampli';
import { LoaderIcon } from '../../../components';
import { useConnectorInitializer } from '../../QuickIntegration/hooks/useConnectorInitializer';
import { useAllInputs } from '../../QuickIntegration/hooks/useInputMetadata';
import { MessageBubble } from './MessageBubble';
import { BackButton, NavigationButtons } from './NavigationButtons';

export const SourceSelector = ({
  source,
  destination,
  onSelect,
  onConnect,
  onBack,
}: {
  source?: IntegrationSource;
  destination?: IntegrationDestination;
  onSelect: (s: IntegrationSource | undefined) => void;
  onConnect: () => void;
  onBack: () => void;
}) => {
  const setSource = onSelect;

  const sources = useAllInputs().map((s) => {
    const connector = s.connectorHook();
    return {
      ...s,
      key: s.key,
      connector,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      isInitialized: useConnectorInitializer(connector),
    };
  });

  const sourcesList = useTrail(sources?.length, {
    from: { translateY: 25, opacity: 0 },
    to: { translateY: 0, opacity: 1 },
    delay: 300,
    config: { tension: 280, friction: 20 },
  });

  const [showCustomScreen, setShowCustomScreen] = useState(false);
  const selectedSourceMeta = sources.find((s) => s.key === source);

  useEffect(() => {
    if (selectedSourceMeta?.connector.isConnected) {
      ampli.sourceEnabled({
        source: selectedSourceMeta.key,
        product: 'crmchat',
      });
      onConnect();
    }
  }, [selectedSourceMeta?.connector.isConnected]);

  const isLoading = !!sources.find((s) => !s.isInitialized);
  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoaderIcon />
      </div>
    );
  }

  if (
    showCustomScreen &&
    selectedSourceMeta &&
    !selectedSourceMeta.connector.isConnected &&
    selectedSourceMeta.connector.customButtonV2
  ) {
    return (
      <div className="h-full flex flex-col justify-between">
        <div className="h-full overflow-y-auto">
          {createElement(selectedSourceMeta.connector.customButtonV2, {
            metadata: selectedSourceMeta,
            back: () => setSource(undefined),
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full flex flex-col justify-between">
      <div className="h-full w-full overflow-y-auto flex flex-col space-y-4 mb-4">
        <MessageBubble>
          ✅ Almost done! What{' '}
          <strong className="font-semibold">💬 messaging service</strong> would
          you like to send me commands on? Pick the most handy
        </MessageBubble>
        <div className="w-full grid mt-4 grid-cols-1 gap-6 sm:grid-cols-2">
          {sourcesList.map((props, index) => {
            const s = sources[index];

            return (
              <animated.button
                type="button"
                style={props}
                key={s.key}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-gray-300 border"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 w-full ">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate font-medium text-gray-900">
                        {s.title}
                      </h3>
                    </div>
                  </div>
                  <img
                    className="h-10 w-10 flex-shrink-0"
                    src={s.logoUrl}
                    alt=""
                  />
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="-ml-px flex w-0 flex-1">
                      <button
                        type="button"
                        onClick={() => {
                          setSource(s.key);
                          setShowCustomScreen(!!s.connector.customButtonV2);
                          if (!s.connector.customButtonV2) {
                            s.connector.connect();
                          }
                          ampli.sourceSignInStarted({
                            source: s.key,
                            destination:
                              destination as CRMIntegration['destination'],
                            product: 'crmchat',
                          });
                        }}
                        className="relative cursor-pointer inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-b-lg py-4 text-sm font-semibold text-gray-800 hover:bg-gray-100 transition-all duration-300 ease-in-out"
                      >
                        <PlusCircleIcon
                          className="h-5 w-5 text-gray-800"
                          aria-hidden="true"
                        />
                        Connect
                      </button>
                    </div>
                  </div>
                </div>
              </animated.button>
            );
          })}
        </div>
      </div>
      <NavigationButtons>
        <BackButton
          type="inline"
          onClick={() => {
            onBack();
          }}
        />
      </NavigationButtons>
    </div>
  );
};
