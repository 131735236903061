//@ts-nocheck
import { useState } from 'react';

export const useAudioRecorder = () => {
  const [audioData, setAudioData] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) => setAudioData(e.data);
      recorder.start();
      setMediaRecorder(recorder);
    } catch (err) {
      console.error('error while recording audio:', err);
    }
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
  };

  return { startRecording, stopRecording, audioData };
};
