import { PlusCircleIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import {
  integrationsArraySelector,
  removeIntegration,
  subscriptionSelector,
} from '@hints/client';
import { IntegrationWithId, getSubscriptionPlan } from '@hints/types';

import { useAppDispatch, useAppSelector } from '../hooks';
import { useAllOutputs } from '../hooks/useOutputMetadata';
import { classNames } from '../utils/classNames';

const FlowLogo = ({ logoUrl, title }: { logoUrl: string; title: string }) => (
  <img
    width={18}
    height={18}
    src={logoUrl}
    alt={title}
    className="inline mr-2"
  />
);

export const CRMConnection = () => {
  const navigate = useNavigate();
  const outputs = useAllOutputs();
  const currentSubscription = useAppSelector(subscriptionSelector);
  const currentPlan = getSubscriptionPlan(currentSubscription) || '';
  const dispatch = useAppDispatch();

  const userIntegrations: IntegrationWithId[] = useAppSelector(
    integrationsArraySelector,
  );

  const hasCRMIntegrations = userIntegrations.some(
    ({ destination }) =>
      destination === 'pipedrive' ||
      destination === 'salesforce' ||
      destination === 'hubspot',
  );

  const plans = ['playbook', 'crm'];
  const isCRMorPlaybookUser =
    (plans.includes(currentPlan) && userIntegrations.length > 1) ||
    hasCRMIntegrations;

  return (
    <section className="w-full h-full p-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Connected CRM
            </h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all tools connected to your account
            </p> */}
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={() => {
                navigate('/');
              }}
              disabled={isCRMorPlaybookUser}
              className={`flex items-center space-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                isCRMorPlaybookUser &&
                'bg-gray-400 hover:bg-gray-400 cursor-not-allowed'
              }`}
            >
              <PlusCircleIcon className="w-4 h-4" />
              <span className="whitespace-nowrap">Create Integration</span>
            </button>
          </div>
        </div>
        <div className="mt-6 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 mt-4">
            <div className="w-full overflow-x-auto py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0 bg-white rounded-md overflow-hidden border border-gray-200">
                <thead>
                  <tr>
                    {/* <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Source
                    </th> */}
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Destination
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userIntegrations.map((integration, index) => {
                    const to = outputs.find(
                      (i) => i.key === integration.destination,
                    );
                    return (
                      <tr
                        key={integration.id}
                        className="bg-transparent rounded-md transition-all duration-300 ease-in-out"
                      >
                        {/* <td
                          className={classNames(
                            index !== userIntegrations.length - 1
                              ? 'border-b border-gray-200'
                              : '',
                            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-800 sm:pl-6 lg:pl-8 flex justify-start items-center h-full',
                          )}
                        >
                          CRM Chat
                        </td> */}
                        <td
                          className={classNames(
                            index !== userIntegrations.length - 1
                              ? 'border-b border-gray-200'
                              : '',
                            'whitespace-nowrap table-cell max-w-xs overflow-hidden px-3 py-4 text-sm text-gray-800 justify-start items-center',
                          )}
                        >
                          {to && (
                            <FlowLogo logoUrl={to.logoUrl} title={to?.title} />
                          )}
                          {to?.title}
                        </td>
                        <td
                          className={classNames(
                            index !== userIntegrations.length - 1
                              ? 'border-b border-gray-200'
                              : '',
                            'relative whitespace-nowrap py-4 pr-3 pl-2 text-left space-x-4 text-sm font-medium',
                          )}
                        >
                          <button
                            type="button"
                            onClick={async () =>
                              dispatch(
                                removeIntegration(
                                  integration as IntegrationWithId,
                                ),
                              )
                            }
                            className="p-1 hover:bg-red-100 transition duration-300 ease-in-out rounded-md"
                          >
                            <TrashIcon className="w-5 h-5 text-red-500" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CRMConnection;
