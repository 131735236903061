import { ArrowLeftCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { startLoadingApp, stopLoadingApp } from '@hints/client';

import mailbox from '../../assets/mailbox.svg';
import { GoBackButton, Intercom, ResponsiveScreen } from '../components';
import { useAppDispatch, useHttpsCallable } from '../hooks';
import { useQueryParam } from '../hooks/useQueryParam';

export const EmailAuth = () => {
  const navigate = useNavigate();
  const [sendSignInLinkToEmail] = useHttpsCallable('sendSignInLinkToEmail');
  const dispatch = useAppDispatch();
  const emailFromQueryParam = useQueryParam('email');
  const [emailAddress, setEmail] = useState(
    emailFromQueryParam || localStorage?.getItem('emailForSignIn') || '',
  );
  const onEmailPress = async () => {
    dispatch(startLoadingApp());
    try {
      await sendSignInLinkToEmail({
        email: emailAddress,
        redirectUrl: `${window.location.origin}/finishSignUp${window.location.search}`,
      });
      toast.success('Email sent!');
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Something went wrong');
    } finally {
      dispatch(stopLoadingApp());
    }
  };

  useEffect(() => {
    if (emailFromQueryParam) {
      localStorage?.setItem('emailForSignIn', emailFromQueryParam);
    }
  }, [emailFromQueryParam]);

  return (
    <section className="w-full h-full flex flex-col justify-center items-center space-y-8">
      <div className="w-full mt-8 flex flex-col items-center z-10 max-w-sm">
        <img
          src={mailbox}
          alt="mailbox"
          className="mb-8 h-[240px] md:h-[200px] md:tall:h-[240px] z-10"
        />

        <p className="mt-3.5 text-gray-800 text-center font-semibold">
          We have sent you a confirmation email to{' '}
          <span className="text-purple-500">{emailAddress}</span>. Please check
          your email and follow the link
        </p>
        <p className="mt-3.5 text-gray-500 text-center text-sm">
          Didn't receive the email?{' '}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            className="underline font-medium cursor-pointer z-10 hover:text-gray-900 transition duration-300 ease-in-out"
            onClick={onEmailPress}
          >
            Send it again
          </span>
        </p>
      </div>
      <button
        onClick={() => navigate('/')}
        type="button"
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 space-x-2"
      >
        <ArrowLeftCircleIcon className="h-5 w-5 text-gray-500" />
        <span>Go Back</span>
      </button>

      <Intercom />
    </section>
  );
};

export default EmailAuth;
