import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import TrelloIcon from '@hints/shared/icons/integrations/Trello.svg';

import TrelloTutorialGif from '../../../../assets/TG-Trello.png';
import TrelloStep2 from '../../../../assets/instructions/trello/step-2.png';
import TrelloStep3 from '../../../../assets/instructions/trello/step-3.png';
import TrelloStep4 from '../../../../assets/instructions/trello/step-4.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { TrelloSetup } from '../setups/TrelloSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const redirectURL =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:4242/integrations/complete-auth'
    : 'https://app.crmchat.ai/integrations/complete-auth';

const authUrl = `https://trello.com/1/authorize?expiration=never&name=Hints&scope=read,write,account&key=d344240a7125757a4964c86d0947e05c&callback_method=fragment&return_url=${redirectURL}`;

const useTrelloConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizeTrello] = useHttpsCallable('authorizeTrello');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl,
    onComplete: async (token) => {
      await authorizeTrello({ token });
    },
  });

  const disconnect = () =>
    dispatch(
      updateProfile({
        trello: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected: !!user.trello?.accessToken,
    connect: startAuth,
    disconnect,
    isLoading,
    error,
  };
};

export const trello: OutputIntegrationMetadata<'trello'> = {
  title: 'Trello',
  logoUrl: TrelloIcon,
  connectorHook: useTrelloConnector,
  defaultTagName: 'trello',
  setupComponent: TrelloSetup,
  tutorial: {
    gif: (
      <img
        src={TrelloTutorialGif}
        alt=""
        className="w-full h-[200px] rounded-lg"
      />
    ),
    examples: [
      '✅ To create a card, simply send the Assistant your text',
      '📝 To send your comment to the card, reply to the original message with the task',
      '👥 To use this bot with your team, add it to your group and use #trello in your requests. Example: Launch a new website #trello',
    ],
  },
  instructions: (
    <div className="space-y-8">
      <div>
        <b>Step 1.</b> Click the “<b>Login to Trello</b>” button below
      </div>
      <div>
        <span>
          <b>Step 2.</b> Click "Log in" in Trello popup window
        </span>
        <img
          src={TrelloStep2}
          alt="step2"
          className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
      <div>
        <span>
          <b>Step 3.</b> Enter your Trello account
        </span>
        <img
          src={TrelloStep3}
          alt="step2"
          className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
      <div>
        <span>
          <b>Step 4.</b> Give access to your Trello space
        </span>
        <img
          src={TrelloStep4}
          alt="step2"
          className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
    </div>
  ),
};
