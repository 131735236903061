export const shortcuts = {
  salesforce: [
    {
      title: 'Create a new lead',
      description: [
        'first & last name, company name, email',
        'links to a new or existing company account, recognizes all configured fields',
      ],
      prompt: 'Create a new contact named {} and link it to a new company, {}.',
    },
    {
      title: 'Add a task',
      description: [
        'task title, details, company account or contact',
        'Understands due dates and associated activities like calls and notes',
      ],
      prompt: 'Add a task to the contact {} to {} by {}.',
    },
    {
      title: 'Log a call with',
      description: [
        'account or contact, call details',
        'Associates it with the right contact and account',
      ],
      prompt: 'Log a call with {} for today at {} and add a note: {}.',
    },
    {
      title: 'Show details for',
      description: [
        'opportunity, contact or account',
        'Ask for any field you need for your next call',
      ],
      prompt:
        'Add a note to the deal {} with the description: {} and action: {}.',
    },
  ],
  hubSpot: [
    {
      title: 'Create a contact',
      description: [
        'first & last name, company name, email',
        'links to a new or existing company, recognizes all configured fields',
      ],
      prompt: 'Create a new contact named {} and link it to a new company, {}.',
    },
    {
      title: 'Add a task',
      description: [
        'task title, details, company or contact or deal name',
        'Understands due dates and associated activities like calls and notes',
      ],
      prompt: 'Add a task to the contact {} to {} by {}.',
    },
    {
      title: 'Log a call with',
      description: [
        'contact or company or deal, call details',
        'Associates it with the right contact, deal, and company',
      ],
      prompt: 'Log a call with {} for today at {} and add a note: {}.',
    },
    {
      title: 'Show details for',
      description: [
        'deal or contact or account',
        'Ask for any field you need for your next call',
      ],
      prompt:
        'Add a note to the deal {} with the description: {} and action: {}.',
    },
  ],
  pipedrive: [
    {
      title: 'Create a new contact person',
      description: [
        'first & last name, organization name, email',
        'links to a new or existing organization and deal, recognizes all configured fields',
      ],
      prompt: 'Create a new contact named {} and link it to a new company, {}.',
    },
    {
      title: 'Add a task',
      description: [
        'task title, details, organization or contact or deal',
        'Understands due dates and associated activities like calls and notes',
      ],
      prompt: 'Add a task to the contact {} to {} by {}.',
    },
    {
      title: 'Log a call with',
      description: [
        'organization or contact or deal, call details',
        'Associates it with the right organization and contact person',
      ],
      prompt: 'Log a call with {} for today at {} and add a note: {}.',
    },
    {
      title: 'Show details for',
      description: [
        'organization or contact or deal',
        'Ask for any field you need for your next call',
      ],
      prompt:
        'Add a note to the deal {} with the description: {} and action: {}.',
    },
  ],
};
