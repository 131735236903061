import { useEffect, useMemo, useState } from 'react';

import { startLoadingApp, stopLoadingApp, updateProfile } from '@hints/client';

import closeTabImage from '../../../assets/closeTabImage.svg';
import { useAppDispatch } from '../../hooks';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useURLHash } from '../../hooks/useURLHash';

export const AUTH_WITHOUT_CODE = '__auth_without_code__';

const CompleteAuth = () => {
  const code = useQueryParam('code');
  const error = useQueryParam('error');
  const token = useURLHash('token');

  const currentOAuth2Code = useMemo(
    () => code || token || AUTH_WITHOUT_CODE,
    [code, token],
  );

  const [isCodeUpdated, setIsCodeUpdated] = useState(!currentOAuth2Code);

  if (error === 'access_denied') {
    window.close();
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(startLoadingApp());
  }, []);

  useEffect(() => {
    if (currentOAuth2Code) {
      const updateAuthCode = async () => {
        await dispatch(
          updateProfile({
            currentOAuth2Code,
          }),
        );
      };

      updateAuthCode()
        .then(() => {
          dispatch(stopLoadingApp());
          setIsCodeUpdated(true);
        })
        .catch(console.error);
    }
  }, [currentOAuth2Code, dispatch]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      {isCodeUpdated ? (
        <div className="p-4 text-center space-y-4">
          <img src={closeTabImage} alt="" className="w-full h-40" />
          <h2 className="text-lg text-gray-700">
            You will be redirected in 3 seconds. If the window does not close,
            close it manually
          </h2>
        </div>
      ) : null}
    </div>
  );
};

export default CompleteAuth;
