import { ArrowUpIcon, MicrophoneIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { T } from 'ramda';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { animated, useSprings } from 'react-spring';

import { integrationsArraySelector } from '@hints/client';
import { profileSelector } from '@hints/client';
import { IntegrationWithId } from '@hints/types';

import { LoaderIcon } from '../components/LoaderIcon';
import { useAppSelector } from '../hooks';
import { useAudioRecorder } from '../hooks/useAudioRecorder';
import { transcribeAudioFile } from '../utils/ai';
import { shortcuts } from '../utils/shortcuts';

const TipsComponent = ({
  showTips,
  setText,
}: {
  showTips: boolean;
  setText: (text: string) => void;
}) => {
  const userIntegrations: IntegrationWithId[] = useAppSelector(
    integrationsArraySelector,
  );
  const tips =
    shortcuts[userIntegrations[0].destination as keyof typeof shortcuts];
  const springs = useSprings(
    tips.length,
    tips.map((_, index) => ({
      transform: showTips ? 'translateY(0)' : 'translateY(50px)',
      from: { transform: 'translateY(50px)' },
      delay: index * 100,
      config: { tension: 170, friction: 26 },
    })),
  );
  return (
    <div className="absolute bottom-28 max-w-full w-full px-2 py-2 grid grid-cols-2 gap-3 bg-white">
      {springs.map((props, index) => (
        <animated.button
          style={props}
          key={tips[index].title}
          onClick={(e) => {
            e.preventDefault();
            setText(tips[index].prompt);
          }}
          className="flex flex-col items-start justify-start px-3 py-2 space-y-2 text-left w-full rounded-md bg-white hover:bg-gray-100 border border-gray-200"
        >
          <p className="text-sm font-semibold text-gray-800 font-sans">
            {tips[index].title}
          </p>
          <p className="text-sm text-neutral-400 italic font-sans font-medium">
            [{tips[index].description[0]}]
          </p>
          <p className="text-sm text-gray-600 font-sans">
            {tips[index].description[1]}
          </p>
        </animated.button>
      ))}
    </div>
  );
};

const ChatInput = ({
  onSubmit,
  replyContent,
  onRemoveReply,
}: {
  onSubmit: (data: string, prompt?: string) => void;
  replyContent: string;
  onRemoveReply: () => void;
}) => {
  const [text, setText] = useState('');
  const [rows, setRows] = useState(1);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState<number | null>(null);
  const [isTranscribationLoading, setIsTranscribationLoading] = useState(false);
  const user = useSelector(profileSelector);
  const [voiceLanguage, setVoiceLanguage] = useState(
    user?.defaultVoiceLanguage || '',
  );
  const [showTips, setShowTips] = useState(false);

  const { startRecording, stopRecording, audioData } = useAudioRecorder();

  const formatTime = (ms: number) => new Date(ms).toISOString().slice(11, 19);

  const toggleRecording = () => {
    if (isTranscribationLoading) {
      return;
    }

    if (!isRecording) {
      setIsRecording(true);
      setTime(0);
      // @ts-ignore
      setTimer(setInterval(() => setTime((t) => t + 1000), 1000));
      startRecording();
    } else {
      setIsRecording(false);
      clearInterval(timer!);
      stopRecording();
    }
  };

  const sendAudioToTranscription = async () => {
    if (!audioData) {
      return;
    }

    setIsTranscribationLoading(true);
    const result = await transcribeAudioFile(
      audioData,
      'audio_to_transcribe',
      voiceLanguage,
    );
    console.info(result);
    if (result) {
      setText(`${text} ${result}`);
    }
    setIsTranscribationLoading(false);
  };

  useEffect(() => {
    sendAudioToTranscription();
  }, [audioData]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setShowTips(false);

    if (!text?.trim()) {
      return;
    }

    if (replyContent) {
      onSubmit(text, replyContent);
      onRemoveReply();
    } else {
      onSubmit(text);
    }

    setText('');
    setRows(1);
  };

  const handleRemoveReply = () => {
    onRemoveReply();
  };

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${Math.min(e.target.scrollHeight, 100)}px`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as any);
    }
    if (e.key === 'Meta' || e.key === 'Alt') {
      setShowTips(!showTips);
    }
  };

  return (
    <form action="#" className="relative" onSubmit={handleSubmit}>
      {showTips && <TipsComponent showTips={showTips} setText={setText} />}
      {replyContent && (
        <div className="absolute -top-9 max-w-full w-full px-3 py-0.5 flex items-center justify-between bg-white">
          <span className="w-full h-full text-ellipsis overflow-hidden whitespace-nowrap text-sm text-gray-700">
            {replyContent}
          </span>
          <button
            type="button"
            onClick={handleRemoveReply}
            className="inline-flex items-center rounded-md p-1 bg-transparent hover:bg-gray-100 transition duration-300 ease-in-out"
          >
            <XMarkIcon className="w-5 h-5 text-gray-500" />
          </button>
        </div>
      )}
      <div className="relative overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        {isTranscribationLoading && (
          <div className="absolute w-full h-10 px-3 flex flex-col justify-center z-10">
            <LoaderIcon />
          </div>
        )}
        <textarea
          ref={textareaRef}
          rows={rows}
          name="description"
          id="description"
          value={text}
          disabled={isTranscribationLoading}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          className={`w-full block rounded-[12px] resize-none border-0 py-3 text-gray-900 placeholder:text-gray-400 ${
            isTranscribationLoading &&
            'text-transparent placeholder:text-transparent'
          } focus:ring-0 text-sm border-none pl-4`}
          placeholder="Message CRM Assistant..."
        />
        <div aria-hidden="true">
          <div className="py-2">
            <div className="h-2" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-2" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-x-px bottom-0">
        <div className="w-full flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            <button
              type="button"
              onClick={toggleRecording}
              disabled={isTranscribationLoading}
              className="group inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
            >
              {isRecording ? (
                <div className="w-3 h-3 m-3 ml-1 mr-4 bg-red-500 rounded-full" />
              ) : (
                <MicrophoneIcon
                  className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              )}
              {isRecording ? (
                <span className="text-sm font-medium text-red-500 group-hover:text-red-600">
                  {formatTime(time)}
                </span>
              ) : (
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-600 whitespace-nowrap">
                  Use voice commands
                </span>
              )}
            </button>
            <select
              disabled={isRecording}
              onChange={(event) => setVoiceLanguage(event.target.value)}
              className="hidden sm:block w-full max-w-[160px] border-0 rounded-md py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={voiceLanguage}
            >
              <option value="">Auto</option>
              <option value="en">English</option>
              <option value="ru">Russian</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
              <option value="de">German</option>
              <option value="it">Italian</option>
              <option value="ja">Japanese</option>
            </select>
          </div>
          <div className="flex-shrink-0">
            <button
              type="submit"
              className="inline-flex items-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 transition-all ease-in-out duration-300"
            >
              <span>Send</span>
              <ArrowUpIcon className="w-5 h-5 ml-1" />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChatInput;
