import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  createIntegration,
  profileSelector,
  startLoadingApp,
  stopLoadingApp,
  updateProfile,
} from '@hints/client';
import { CRMIntegration, Integration } from '@hints/types';

import { ampli } from '../../../../ampli';
import integrationSuccessImage from '../../../../assets/integration-success.png';
import { Button } from '../../../components';
import { useAppDispatch, useAppSelector, useEffectOnce } from '../../../hooks';
import { useInputMetadata } from '../../QuickIntegration/hooks/useInputMetadata';
import { StepComponent, StepComponentProps } from './StepComponent';

export const CompleteStep: StepComponent = ({
  integration,
  source,
  metadata,
}: StepComponentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(profileSelector);
  const sourceMetadata = useInputMetadata(source);
  const sourceConnector = sourceMetadata?.connectorHook();

  const [isConfettiRunning, setIsConfettiRunning] = useState(true);

  const saveIntegration = async () => {
    dispatch(startLoadingApp());
    await dispatch(createIntegration(integration as Integration));

    if (!user.integrationOnboardingStatus?.completed) {
      await dispatch(
        updateProfile({
          id: user.id,
          integrationOnboardingStatus: { completed: true },
        }),
      );
    }
    dispatch(stopLoadingApp());
  };

  useEffectOnce(() => {
    saveIntegration();
    ampli.destinationSignInDone({
      destination: metadata.key as CRMIntegration['destination'],
      product: 'crmchat',
    });
    ampli.flowCreated({
      source: source!,
      destination: metadata.key as CRMIntegration['destination'],
      product: 'crmchat',
    });
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsConfettiRunning(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  if (!sourceMetadata || !sourceConnector) {
    return <Navigate to="/" />;
  }

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="flex flex-col items-center h-full overflow-y-auto mb-6">
        <div className="my-5">
          <img src={integrationSuccessImage} alt="Success" className="h-60" />
        </div>
        <h3 className="mt-4 text-2xl font-bold">The integration is live!</h3>
        <p className="mt-4 text-center text-gray-700">
          Now you can go back to {sourceMetadata.title}, the Assistant will send
          you a message with examples of how to use this integration. You can
          tune your Assistant at the{' '}
          <a
            href={`https://app.crmchat.ai/integrations/output/${metadata.key}/${integration.id}`}
          >
            Settings Page
          </a>{' '}
          if needed.
        </p>
      </div>
      <Button
        isFull
        size="big"
        type="primary"
        isRounded
        leftContent={
          <img
            src={sourceMetadata.logoUrl}
            className="w-6 h-6 mr-2"
            alt={`${sourceMetadata.title} logo`}
          />
        }
        text={`Let's Go`}
        onClick={() => {
          if (sourceConnector.open) {
            sourceConnector.open();
          } else {
            navigate('/');
          }
        }}
      />
      <Confetti
        numberOfPieces={isConfettiRunning ? 100 : 0}
        width={window.innerWidth > 370 ? 370 : window.innerWidth}
        height={window.innerHeight > 750 ? 750 : window.innerHeight}
      />
    </div>
  );
};
