import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import {
  createIntegration,
  profileSelector,
  startLoadingApp,
  stopLoadingApp,
  updateProfile,
} from '@hints/client';
import { Integration } from '@hints/types';

import { useAppDispatch, useAppSelector, useEffectOnce } from '../../hooks';
import { StepComponent, StepComponentProps } from './StepComponent';

export const CompletePlaybookStep: StepComponent = ({
  integration,
}: StepComponentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(profileSelector);

  const saveIntegration = async () => {
    dispatch(startLoadingApp());
    await dispatch(createIntegration(integration as Integration));

    if (!user.integrationOnboardingStatus?.completed) {
      await dispatch(
        updateProfile({
          id: user.id,
          integrationOnboardingStatus: { completed: true },
        }),
      );
    }
    dispatch(stopLoadingApp());
  };

  useEffectOnce(() => {
    saveIntegration();
    navigate('/chat');
    toast.success('Integration created successfully', { duration: 2500 });
  });

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="flex flex-col items-center h-full overflow-y-auto">
        <h3 className="mt-4 text-2xl text-center font-semibold">
          You will be redirected right away
        </h3>
      </div>
    </div>
  );
};
