import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import SalesforceIcon from '@hints/shared/icons/integrations/Salesforce.svg';

import HubspotTutorialGif from '../../../../assets/TG-Hubspot.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { SalesforceSetup } from '../setups/SalesforceSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const CLIENT_ID =
  '3MVG95nWQGdmAiEry1HeBiX.MoelwuMm3qwAw7MvD.i1IsedHpti.RN6VcEKEqRXHehrRGWkUpTpdodnEWQf1';
const AUTH_URL = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}`;

const useConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizeSalesforce] = useHttpsCallable<void | { error: string }>(
    'authorizeSalesforce',
  );
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl: AUTH_URL,
    onComplete: async (code, redirectUri) => {
      const result = await authorizeSalesforce({ code, redirectUri });
      return result?.data?.error ? { message: result.data.error } : null;
    },
  });

  const disconnect = () =>
    dispatch(
      updateProfile({
        salesforce: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected: !!user.salesforce?.accessToken,
    connect: startAuth,
    isLoading,
    disconnect,
    error,
  };
};

export const salesforce: OutputIntegrationMetadata<'salesforce'> = {
  title: 'Salesforce',
  logoUrl: SalesforceIcon,
  connectorHook: useConnector,
  defaultTagName: 'salesforce',
  setupComponent: SalesforceSetup,
  tutorial: {
    gif: (
      <img
        src={HubspotTutorialGif}
        alt=""
        className="w-full h-[160px] rounded-lg"
      />
    ),
    examples: [
      '✅ Now you can create and update your deals. Just tell the Assistant what you want in your own words. See examples below:',
      '🤖 Create a new deal Microsoft Bill Gates bill@microsoft.com',
      '🤖 Remind me to follow up with Bill Gates from Microsoft on March 17th',
      '🤖 Call John from IBM tomorrow',
      '🤖 Add a note to IBM: Meeting went great. I’m waiting for the final approval',
      '👥 To use this bot with your team, add it to your group and use #pipedrive in your requests. Example: Move IBM to Demo Scheduled #hubspot',
    ],
  },
  authButtonText: 'Login',
  instructions: (
    <div className="space-y-4">
      <div>
        Click the “<b>Login</b>” button below and enter your account
      </div>
      <div>
        <span className="text-gray-700 italic text-sm">
          ⚠️ If you are already logged into your account, you will be
          automatically redirected to the next page
        </span>
      </div>
    </div>
  ),
};
