import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stripe } from 'stripe';

import { integrationsArraySelector, subscriptionSelector } from '@hints/client';
import {
  IntegrationWithId,
  getSubscriptionPlan,
  isSalesDestination,
} from '@hints/types';

import { ampli } from '../../../../ampli';
import { environment } from '../../../../environments/environment';
import { Loader } from '../components/Loader';
import { useAppSelector, useEffectOnce, useHttpsCallable } from '../hooks';
import { useAllOutputs } from '../hooks/useOutputMetadata';

type PricingTable = Array<
  Stripe.Product & {
    lookupKey: string | null;
    prices: {
      yearly: { id: string; unit_amount: number } | null;
      monthly: { id: string; unit_amount: number } | null;
    };
    features: { name: string }[];
  }
>;

const getStripeData = (data: object) => ({
  ...data,
  ...(environment.stripeTestMode
    ? { testMode: environment.stripeTestMode }
    : {}),
});

const usePricingTable = (): PricingTable | null => {
  const [getPricingTable] = useHttpsCallable<PricingTable>('getPricingTable');
  const [pricingTable, setPricingTable] = useState<PricingTable | null>(null);
  useEffectOnce(() => {
    const loadTable = async () => {
      const res = await getPricingTable(getStripeData({}));
      if (res?.data) {
        setPricingTable(res.data);
      }
    };
    loadTable();
  });

  return pricingTable;
};

const useProductPriceId = (lookupKey: string) => {
  const pricingTable = usePricingTable();

  if (!pricingTable) return null;

  const product = pricingTable.find((p) => p.lookupKey === lookupKey);
  if (!product) return null;

  const monthlyPriceId = product.prices.monthly?.id || null;

  return monthlyPriceId;
};

export const DestinationCard = ({
  title,
  logo,
  onIntegrationClick,
}: {
  title: string;
  logo: string;
  onIntegrationClick: () => void;
}) => (
  <button
    key={title}
    onClick={onIntegrationClick}
    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white hover:bg-gray-100 border-gray-300 border transition-all duration-300 ease-in-out"
  >
    <div className="flex w-full items-center justify-between space-x-6 p-6">
      <div className="flex-1 w-full ">
        <div className="flex items-center space-x-3">
          <h3 className="truncate font-medium text-gray-900">{title}</h3>
        </div>
      </div>
      <img className="h-10 w-10 flex-shrink-0" src={logo} alt="" />
    </div>
  </button>
);

export const ChooseCRMDestination = () => {
  const navigate = useNavigate();
  const allOutputs = useAllOutputs();
  const currentSubscription = useAppSelector(subscriptionSelector);
  const currentPlan = getSubscriptionPlan(currentSubscription);
  const priceId = useProductPriceId('crm');
  const salesDestinations = allOutputs.filter((dest) =>
    isSalesDestination(dest.key),
  );

  const [loading, setLoading] = useState(true);

  const userIntegrations: IntegrationWithId[] = useAppSelector(
    integrationsArraySelector,
  );

  useEffect(() => {
    if (userIntegrations.length > 0) {
      navigate('/chat');
    }

    setLoading(false);
  }, [currentSubscription, currentPlan, navigate, userIntegrations, priceId]);

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader text="Checking your account status..." />
      </div>
    );
  }

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="text-center">
          <h1 className="text-2xl font-semibold tracking-tight text-gray-900 mb-8">
            Connect CRM
          </h1>
          <div className="w-full grid mt-4 grid-cols-1 gap-6 sm:grid-cols-2">
            {salesDestinations.map(({ title, logoUrl, key }) => (
              <DestinationCard
                key={title}
                title={title}
                logo={logoUrl}
                onIntegrationClick={() => {
                  navigate(`/connect/crm/${key}?destinationSelected=1`);
                }}
              />
            ))}
          </div>
          {/* <div className="hidden sm:mt-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Have questions?{' '}
              <a
                href="https://calendly.com/hints/45min"
                target="_blank"
                rel="noreferrer"
                className="font-semibold text-indigo-600"
                onClick={() => {
                  ampli.integrationSetupOnboardingCallClicked({
                    product: 'crmchat',
                  });
                }}
              >
                <span className="absolute inset-0" aria-hidden="true" />
                Book a call with our AI specialist
                <span aria-hidden="true" className="ml-1">
                  &rarr;
                </span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  );
};
