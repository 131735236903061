import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';
import { createElement, useEffect, useState } from 'react';

import { profileSelector } from '@hints/client';

import {
  BackButton,
  NavigationButtons,
  NextButton,
} from '../../components/NavigationButtons';
import { useAppSelector, useHttpsCallable } from '../../hooks';
import { StepComponent, StepComponentProps } from './StepComponent';

export const DestinationSetupStep: StepComponent = ({
  navigation,
  metadata,
  source,
  integration,
  setIntegration,
}: StepComponentProps) => {
  const [isIntegrationCanBeSaved, setIsIntegrationCanBeSaved] = useState(
    !metadata.setupComponent,
  );

  const [isNextButtonVisible, setIsNextButtonVisible] = useState(true);
  const [publishButtonPressed, setPublishButtonPressed] = useState(false);
  const user = useAppSelector(profileSelector);
  const [updateHubspotContact] = useHttpsCallable(
    'updateHubspotContactCallable',
  );

  const save = async () => {
    setPublishButtonPressed(true);

    updateHubspotContact({
      email: user.email,
      properties: [
        {
          property: 'integrations',
          value: metadata.key,
        },
      ],
    }).catch((e) => console.error('Unable to update hubspot contact', e));

    if (!integration || !isIntegrationCanBeSaved) {
      return;
    }

    navigation.next?.();
  };

  // return user back if connector is not connected
  useEffect(() => {
    if (!metadata.connector.isConnected) {
      navigation.back?.();
    }
  }, [navigation, metadata.connector.isConnected]);

  // skip this step if there is no setup component
  useEffect(() => {
    if (integration && !metadata.setupComponent) {
      navigation.advance();
    }
  }, [navigation, integration, metadata.setupComponent]);

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="h-full overflow-y-visible mb-12">
        {integration &&
        metadata.setupComponent &&
        metadata.connector.isConnected
          ? createElement(metadata.setupComponent, {
              integration,
              setIntegrationData: (data: any) => {
                setIntegration((prev) => ({ ...prev, ...data }));
              },
              setIsIntegrationCanBeSaved,
              publishButtonPressed,
              setIsNextButtonVisible,
              navigation,
              setupStyle: 'v2',
            })
          : null}
      </div>
      <NavigationButtons>
        <BackButton
          onClick={async () => {
            navigation.back?.();
          }}
        />
        {isNextButtonVisible ? (
          <NextButton
            text="Next"
            rightContent={
              <ArrowRightCircleIcon className="w-4 h-4 text-white" />
            }
            onClick={save}
          />
        ) : null}
      </NavigationButtons>
    </div>
  );
};
