import React from 'react';

import './Loader.scss';

export const Loader = ({ text }: { text?: string }) => (
  <div className="space-y-4 flex flex-col justify-center items-center">
    <div className="book">
      <div className="inner">
        <div className="left"></div>
        <div className="middle"></div>
        <div className="right"></div>
      </div>
      <ul>
        {[...Array(18)].map((_, i) => (
          <li key={i} />
        ))}
      </ul>
    </div>
    <span className="text-sm font-medium text-gray-700">
      {text || 'Transcribing'}
    </span>
  </div>
);

export default Loader;
