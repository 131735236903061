/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { integrationsArraySelector } from '@hints/client';

import { ampli } from '../../../../../ampli';
import {
  RemoteSelect,
  useHttpsCallableRemote,
} from '../../components/remote-select';
import { useAppSelector, useEffectExceptOnMount } from '../../hooks';
import { SetupComponent } from '../types';
import { FlowSection } from './FlowSection';

export const PipedriveSetup: SetupComponent<'pipedrive'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
}) => {
  const [pipelineId, setPipelineId] = useState<string | undefined>(
    integration.pipelineId,
  );

  const pipelinesRemote = useHttpsCallableRemote('getCrmPipelines', {
    serviceId: 'pipedrive',
  });

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const stagesRemote = useHttpsCallableRemote('getCrmPipeline', {
    serviceId: 'pipedrive',
    id: pipelineId,
  });

  useEffectExceptOnMount(() => {
    if (pipelineId) {
      stagesRemote.reload();
    }
  }, [pipelineId]);

  useEffect(() => {
    setIntegrationData({ pipelineId });
    setIsIntegrationCanBeSaved(!!pipelineId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineId]);

  return (
    <>
      <FlowSection
        completed={!!pipelineId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!pipelineId && publishButtonPressed}
        errorText="select a pipeline"
        variant={setupStyle}
      >
        Select pipeline and stage
      </FlowSection>
      <div className="flex flex-col space-y-4">
        <RemoteSelect
          remote={pipelinesRemote}
          value={pipelineId}
          onChange={(value) => {
            setPipelineId(value);
            ampli.integrationSetupPipelineSelected({
              product: 'crmchat',
            });
          }}
          placeholder="Select pipeline"
          selectFirst
          variant={setupStyle}
        />
      </div>
    </>
  );
};

export default PipedriveSetup;
