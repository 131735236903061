/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { integrationsArraySelector } from '@hints/client';

import {
  RemoteSelect,
  useHttpsCallableRemote,
} from '../../../components/remote-select';
import { useAppSelector, useEffectExceptOnMount } from '../../../hooks';
import { FlowSection } from '../FlowSection';
import { SetupComponent } from '../types';

export const PipedriveSetup: SetupComponent<'pipedrive'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
}) => {
  const [pipelineId, setPipelineId] = useState<string | undefined>(
    integration.pipelineId,
  );
  const [stageId, setStageId] = useState<string | undefined>(
    integration.stageId,
  );

  const [stages, setStages] = useState([]);

  const pipelinesRemote = useHttpsCallableRemote('getCrmPipelines', {
    serviceId: 'pipedrive',
  });

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const stagesRemote = useHttpsCallableRemote('getCrmPipeline', {
    serviceId: 'pipedrive',
    id: pipelineId,
  });

  useEffectExceptOnMount(() => {
    if (pipelineId) {
      stagesRemote.reload();
    }
  }, [pipelineId]);

  useEffect(() => {
    if (stagesRemote.options) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setStages(stagesRemote.options?.stages);
    }
  }, [stagesRemote.options]);

  useEffect(() => {
    setIntegrationData({ pipelineId });
    setIsIntegrationCanBeSaved(!!pipelineId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineId]);

  return (
    <>
      <FlowSection
        completed={!!pipelineId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!pipelineId && publishButtonPressed}
        errorText="select a pipeline"
        variant={setupStyle}
      >
        Select pipeline and stage
      </FlowSection>
      <div className="flex flex-col space-y-4">
        <RemoteSelect
          remote={pipelinesRemote}
          value={pipelineId}
          onChange={setPipelineId}
          placeholder="Select pipeline"
          selectFirst
          variant={setupStyle}
        />
        {/* {pipelineId ? (
          <RemoteSelect
            remote={{
              ...stagesRemote,
              options: stages,
            }}
            value={stageId}
            onChange={setStageId}
            placeholder="Select stage"
            disabled={!pipelineId}
            selectFirst
            variant={setupStyle}
          />
        ) : null} */}
      </div>
    </>
  );
};

export default PipedriveSetup;
