/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 64
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/slpbx/Hints/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production';

export const ApiKey: Record<Environment, string> = {
  production: '8d158e561e9fce62280dc9535093b9f2'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '64',
    branch: 'main',
    source: 'web',
    versionId: 'e47f222d-4a35-4571-80d9-931636c82c1a'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * current number of flows that are active for this user
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  active_flows?: number;
  /**
   * When a user connects an integration the name of the integration becomes active in user property.
   *
   * If hubspot and google-calendar were connected both should be displayed in this property
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  active_integrations?: (
    | "hubspot"
    | "salesforce"
    | "trello"
    | "obsidian"
    | "notion"
    | "google-calendar"
    | "pipedrive"
    | "jira"
    | "clickup"
    | "coda"
  )[];
  /**
   * True if user is admin, False if not an admin
   */
  admin?: boolean;
  /**
   * User email address provided either with login or after logging in to a 3rd party software
   */
  email?: string;
  fbclid?: any;
  gclid?: any;
  id?: any;
  initial_dclid?: string;
  initial_fbclid?: any;
  initial_gbraid?: string;
  initial_gclid?: any;
  initial_ko_click_id?: string;
  initial_msclkid?: string;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: string;
  initial_twclid?: string;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_id?: string;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: string;
  initial_wbraid?: string;
  name?: any;
  notion?: {
    email?: string;
  };
  referrer?: any;
  referring_domain?: any;
  /**
   * Defined user's role. For now just `admin` or nothing
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | admin |
   */
  role?: "admin";
  /**
   * Timestamp: when did the user perform a Signup event
   */
  signed_up_at?: any;
  /**
   * Last subscription status of the user
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | paid, paid_cancelled, trial, trial_cancelled |
   */
  subscription?: "paid" | "paid_cancelled" | "trial" | "trial_cancelled";
  /**
   * Current subscription status after the last subscription event
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | active, inactive |
   */
  subscriptionStatus?: "active" | "inactive";
  timezone?: any;
  twclid?: string;
  utm_campaign?: any;
  utm_content?: any;
  utm_medium?: any;
  utm_source?: any;
  utm_term?: any;
}

export interface CheckoutStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface DestinationSelectedProperties {
  /**
   * A destination that is being configured or is used. Can sometimes be filled when the source is empty
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | notion, clickup, trello, jira, pipedrive, hubspot, obsidian, google-calendar, coda, none, salesforce |
   */
  destination:
    | "notion"
    | "clickup"
    | "trello"
    | "jira"
    | "pipedrive"
    | "hubspot"
    | "obsidian"
    | "google-calendar"
    | "coda"
    | "none"
    | "salesforce";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
  /**
   * Sample values: app, telegram, whatsapp, sms, slack, email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | telegram, whatsapp, sms, email, raycast, slack, whatsapp-group, mobile |
   */
  source?: "telegram" | "whatsapp" | "sms" | "email" | "raycast" | "slack" | "whatsapp-group" | "mobile";
}

export interface DestinationSignInDoneProperties {
  /**
   * A destination that is being configured or is used. Can sometimes be filled when the source is empty
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | notion, clickup, trello, jira, pipedrive, hubspot, obsidian, google-calendar, coda, none, salesforce |
   */
  destination:
    | "notion"
    | "clickup"
    | "trello"
    | "jira"
    | "pipedrive"
    | "hubspot"
    | "obsidian"
    | "google-calendar"
    | "coda"
    | "none"
    | "salesforce";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface DestinationSignInPageOpenedProperties {
  /**
   * A destination that is being configured or is used. Can sometimes be filled when the source is empty
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | notion, clickup, trello, jira, pipedrive, hubspot, obsidian, google-calendar, coda, none, salesforce |
   */
  destination:
    | "notion"
    | "clickup"
    | "trello"
    | "jira"
    | "pipedrive"
    | "hubspot"
    | "obsidian"
    | "google-calendar"
    | "coda"
    | "none"
    | "salesforce";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface FlowCreatedProperties {
  /**
   * A destination that is being configured or is used. Can sometimes be filled when the source is empty
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | notion, clickup, trello, jira, pipedrive, hubspot, obsidian, google-calendar, coda, none, salesforce |
   */
  destination:
    | "notion"
    | "clickup"
    | "trello"
    | "jira"
    | "pipedrive"
    | "hubspot"
    | "obsidian"
    | "google-calendar"
    | "coda"
    | "none"
    | "salesforce";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
  /**
   * Sample values: app, telegram, whatsapp, sms, slack, email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | telegram, whatsapp, sms, email, raycast, slack, whatsapp-group, mobile, extension, crmchat |
   */
  source:
    | "telegram"
    | "whatsapp"
    | "sms"
    | "email"
    | "raycast"
    | "slack"
    | "whatsapp-group"
    | "mobile"
    | "extension"
    | "crmchat";
}

export interface IntegrationSetupOnboardingCallClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface IntegrationSetupPipelineSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface IntegrationSetupStageSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface LoginProperties {
  /**
   * How did user authorize? Sample values: google, apple, whatsapp, telegram, sms, email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | google, apple, whatsapp, telegram, sms, email, slack |
   */
  auth_with: "google" | "apple" | "whatsapp" | "telegram" | "sms" | "email" | "slack";
  /**
   * How did user reach this action: click, qr
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | click, qr |
   */
  method?: "click" | "qr";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface OnCallFieldPopulatedProperties {
  ai_guess: string;
  filed_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
  user_input: string;
}

export interface OnCallPlaybookOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface OnCallTargetDealSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface PlaybookEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface PlaybookEditingFieldAddedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface PlaybookEditingFieldConnectedProperties {
  /**
   * A destination that is being configured or is used. Can sometimes be filled when the source is empty
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | notion, clickup, trello, jira, pipedrive, hubspot, obsidian, google-calendar, coda, none, salesforce |
   */
  destination:
    | "notion"
    | "clickup"
    | "trello"
    | "jira"
    | "pipedrive"
    | "hubspot"
    | "obsidian"
    | "google-calendar"
    | "coda"
    | "none"
    | "salesforce";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface SetupPlaybookCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface SignupProperties {
  /**
   * How did user authorize? Sample values: google, apple, whatsapp, telegram, sms, email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | google, apple, whatsapp, telegram, sms, email, slack |
   */
  auth_with: "google" | "apple" | "whatsapp" | "telegram" | "sms" | "email" | "slack";
  /**
   * How did user reach this action: click, qr
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | click, qr |
   */
  method?: "click" | "qr";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
}

export interface SourceEnabledProperties {
  /**
   * A destination that is being configured or is used. Can sometimes be filled when the source is empty
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | notion, clickup, trello, jira, pipedrive, hubspot, google_calendar, obsidian |
   */
  destination?: "notion" | "clickup" | "trello" | "jira" | "pipedrive" | "hubspot" | "google_calendar" | "obsidian";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
  /**
   * Sample values: app, telegram, whatsapp, sms, slack, email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | telegram, whatsapp, sms, email, raycast, slack, whatsapp-group, mobile, extension, crmchat |
   */
  source:
    | "telegram"
    | "whatsapp"
    | "sms"
    | "email"
    | "raycast"
    | "slack"
    | "whatsapp-group"
    | "mobile"
    | "extension"
    | "crmchat";
}

export interface SourceSignInStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  destination?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hints.so, playbook, crmchat, productivity |
   */
  product?: "hints.so" | "playbook" | "crmchat" | "productivity";
  /**
   * Sample values: app, telegram, whatsapp, sms, slack, email
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | telegram, whatsapp, sms, email, raycast, slack, whatsapp-group, mobile, extension, crmchat |
   */
  source:
    | "telegram"
    | "whatsapp"
    | "sms"
    | "email"
    | "raycast"
    | "slack"
    | "whatsapp-group"
    | "mobile"
    | "extension"
    | "crmchat";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStarted implements BaseEvent {
  event_type = 'Checkout Started';

  constructor(
    public event_properties?: CheckoutStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationSelected implements BaseEvent {
  event_type = 'Destination Selected';

  constructor(
    public event_properties: DestinationSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationSignInDone implements BaseEvent {
  event_type = 'Destination Sign In Done';

  constructor(
    public event_properties: DestinationSignInDoneProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DestinationSignInPageOpened implements BaseEvent {
  event_type = 'Destination Sign In Page Opened';

  constructor(
    public event_properties: DestinationSignInPageOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FlowCreated implements BaseEvent {
  event_type = 'Flow Created';

  constructor(
    public event_properties: FlowCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationSetupOnboardingCallClicked implements BaseEvent {
  event_type = 'Integration Setup Onboarding Call Clicked';

  constructor(
    public event_properties?: IntegrationSetupOnboardingCallClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationSetupPipelineSelected implements BaseEvent {
  event_type = 'Integration Setup Pipeline Selected';

  constructor(
    public event_properties?: IntegrationSetupPipelineSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntegrationSetupStageSelected implements BaseEvent {
  event_type = 'Integration Setup Stage Selected';

  constructor(
    public event_properties?: IntegrationSetupStageSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Login implements BaseEvent {
  event_type = 'Login';

  constructor(
    public event_properties: LoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnCallFieldPopulated implements BaseEvent {
  event_type = 'On Call Field Populated';

  constructor(
    public event_properties: OnCallFieldPopulatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnCallPlaybookOpened implements BaseEvent {
  event_type = 'On Call Playbook Opened';

  constructor(
    public event_properties?: OnCallPlaybookOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnCallTargetDealSelected implements BaseEvent {
  event_type = 'On Call Target Deal Selected';

  constructor(
    public event_properties?: OnCallTargetDealSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlaybookEdited implements BaseEvent {
  event_type = 'Playbook Edited';

  constructor(
    public event_properties?: PlaybookEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlaybookEditingFieldAdded implements BaseEvent {
  event_type = 'Playbook Editing Field Added';

  constructor(
    public event_properties?: PlaybookEditingFieldAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlaybookEditingFieldConnected implements BaseEvent {
  event_type = 'Playbook Editing Field Connected';

  constructor(
    public event_properties: PlaybookEditingFieldConnectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlaybookExtensionInstallButtonClicked implements BaseEvent {
  event_type = 'Playbook Extension Install Button Clicked';
}

export class SetupPlaybookCreated implements BaseEvent {
  event_type = 'Setup Playbook Created';

  constructor(
    public event_properties?: SetupPlaybookCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Signup implements BaseEvent {
  event_type = 'Signup';

  constructor(
    public event_properties: SignupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SourceEnabled implements BaseEvent {
  event_type = 'Source Enabled';

  constructor(
    public event_properties: SourceEnabledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SourceSignInStarted implements BaseEvent {
  event_type = 'Source Sign In Started';

  constructor(
    public event_properties: SourceSignInStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Checkout Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Checkout%20Started)
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  checkoutStarted(
    properties?: CheckoutStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutStarted(properties), options);
  }

  /**
   * Destination Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Destination%20Selected)
   *
   * When the user visits i.hints.so/connect/sales or other URLs in other products that suggest to select a CRM platform for integration
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  destinationSelected(
    properties: DestinationSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationSelected(properties), options);
  }

  /**
   * Destination Sign In Done
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Destination%20Sign%20In%20Done)
   *
   * We received response from destination i.e. hubspot that the authorization is complete
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  destinationSignInDone(
    properties: DestinationSignInDoneProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationSignInDone(properties), options);
  }

  /**
   * Destination Sign In Page Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Destination%20Sign%20In%20Page%20Opened)
   *
   * When user clicked on "connect" to destination button that opens auth screen to destination (not when we receive a server response)
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  destinationSignInPageOpened(
    properties: DestinationSignInPageOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DestinationSignInPageOpened(properties), options);
  }

  /**
   * Flow Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Flow%20Created)
   *
   * user has published an integration and it has been saved
   *
   * If a source is required to finish setup, count after both source and destination are connected.
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  flowCreated(
    properties: FlowCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FlowCreated(properties), options);
  }

  /**
   * Integration Setup Onboarding Call Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Integration%20Setup%20Onboarding%20Call%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  integrationSetupOnboardingCallClicked(
    properties?: IntegrationSetupOnboardingCallClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationSetupOnboardingCallClicked(properties), options);
  }

  /**
   * Integration Setup Pipeline Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Integration%20Setup%20Pipeline%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  integrationSetupPipelineSelected(
    properties?: IntegrationSetupPipelineSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationSetupPipelineSelected(properties), options);
  }

  /**
   * Integration Setup Stage Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Integration%20Setup%20Stage%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  integrationSetupStageSelected(
    properties?: IntegrationSetupStageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationSetupStageSelected(properties), options);
  }

  /**
   * Login
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Login)
   *
   * The user successfully logged in to the web or mobile application
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. auth_with)
   * @param options Amplitude event options.
   */
  login(
    properties: LoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new Login(properties), options);
  }

  /**
   * On Call Field Populated
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/On%20Call%20Field%20Populated)
   *
   * When a field value is recognized by ai and is populated with content
   *
   * @param properties The event's properties (e.g. ai_guess)
   * @param options Amplitude event options.
   */
  onCallFieldPopulated(
    properties: OnCallFieldPopulatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnCallFieldPopulated(properties), options);
  }

  /**
   * On Call Playbook Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/On%20Call%20Playbook%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  onCallPlaybookOpened(
    properties?: OnCallPlaybookOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnCallPlaybookOpened(properties), options);
  }

  /**
   * On Call Target Deal Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/On%20Call%20Target%20Deal%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  onCallTargetDealSelected(
    properties?: OnCallTargetDealSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnCallTargetDealSelected(properties), options);
  }

  /**
   * Playbook Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Playbook%20Edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  playbookEdited(
    properties?: PlaybookEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaybookEdited(properties), options);
  }

  /**
   * Playbook Editing Field Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Playbook%20Editing%20Field%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  playbookEditingFieldAdded(
    properties?: PlaybookEditingFieldAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaybookEditingFieldAdded(properties), options);
  }

  /**
   * Playbook Editing Field Connected
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Playbook%20Editing%20Field%20Connected)
   *
   * Connected field to CRM
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  playbookEditingFieldConnected(
    properties: PlaybookEditingFieldConnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlaybookEditingFieldConnected(properties), options);
  }

  /**
   * Playbook Extension Install Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Playbook%20Extension%20Install%20Button%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  playbookExtensionInstallButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new PlaybookExtensionInstallButtonClicked(), options);
  }

  /**
   * Setup Playbook Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Setup%20Playbook%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. product)
   * @param options Amplitude event options.
   */
  setupPlaybookCreated(
    properties?: SetupPlaybookCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetupPlaybookCreated(properties), options);
  }

  /**
   * Signup
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Signup)
   *
   * User signs up with the application
   *
   * = first login, the moment a new unique ID is created for a user
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. auth_with)
   * @param options Amplitude event options.
   */
  signup(
    properties: SignupProperties,
    options?: EventOptions,
  ) {
    return this.track(new Signup(properties), options);
  }

  /**
   * Source Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Source%20Enabled)
   *
   * user has completed setting up a source: whatsapp, telegram, slack, SMS, email or other. (Server response)
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  sourceEnabled(
    properties: SourceEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SourceEnabled(properties), options);
  }

  /**
   * Source Sign In Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/slpbx/Hints/events/main/latest/Source%20Sign%20In%20Started)
   *
   * When the user selects (clicks) a button that starts log in to a source (WhatsApp telegram sms slack)
   *
   * Owner: Alexey Loktev
   *
   * @param properties The event's properties (e.g. destination)
   * @param options Amplitude event options.
   */
  sourceSignInStarted(
    properties: SourceSignInStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SourceSignInStarted(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
