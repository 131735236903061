import { Transition } from '@headlessui/react';
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { profileSelector } from '@hints/client';

import { ampli } from '../../../../ampli';
import { useHttpsCallable } from '../hooks';
import ChatInput from './ChatInput';
import Message from './Message';

type MessageType = {
  sender: 'user' | 'gpt';
  content: string;
};

const mockMessage: MessageType = {
  sender: 'gpt',
  content:
    'Now, you can update your CRM. I understand text and voice commands. Send me these examples to see how I work:   \n - Create a new contact named John Smith and link him to a new company, IBM.  \n - Log a call with John Smith for today at 8 AM and add a note: They are waiting for the proposal.  \n - Add a task to the contact John Smith to send the proposal by tomorrow at 2 PM.',
};

const Chat = () => {
  const [messages, setMessages] = useState<MessageType[]>([mockMessage]);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [replyContent, setReplyContent] = useState('');
  const user = useSelector(profileSelector);
  const [createChatMessage] = useHttpsCallable('createChatMessage');

  const handleReply = (content: string) => {
    setReplyContent(content);
  };

  const handleRemoveReply = () => {
    setReplyContent('');
  };

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const savedMessages = localStorage.getItem('savedMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
    const db = getFirestore();
    const unsubscribe = onSnapshot(
      query(collection(db, 'chats'), where('userId', '==', user.id)),
      (querySnapshot) => {
        const dbMessages: (MessageType & { createdAt?: any })[] = [];
        querySnapshot.forEach((doc) => {
          dbMessages.push(doc.data() as MessageType);
        });
        dbMessages.sort((a, b) => {
          if (a.createdAt.seconds === b.createdAt.seconds) {
            return a.createdAt.nanoseconds - b.createdAt.nanoseconds;
          }
          return a.createdAt.seconds - b.createdAt.seconds;
        });
        const newMessages = [
          mockMessage,
          ...dbMessages.map((message: any) => ({
            ...message,
            sender: message.author,
            content: message.text,
          })),
        ];
        setMessages(newMessages);
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (inputValue: string) => {
    if (inputValue.trim() === '') return;

    setMessages((prevMessages) => {
      const newMessages = [
        ...prevMessages,
        { sender: 'user', content: inputValue } as MessageType,
      ];
      localStorage.setItem('savedMessages', JSON.stringify(newMessages));
      return newMessages;
    });

    await createChatMessage({
      userID: user.id,
      message: inputValue,
      source: 'crmchat',
    });
  };

  const os = window.navigator.platform;
  const osShortcut = os.includes('Mac') ? 'Option' : 'Alt';

  return (
    <div className="relative flex flex-col justify-between items-center w-full h-full overflow-hidden pt-4">
      <div className="flex flex-col p-3 w-full h-full max-h-full overflow-y-auto overflow-x-hidden mb-40 scroll-smooth lg:max-w-4xl">
        <div className="w-full flex flex-col space-y-1 justify-start h-fit">
          {messages.map((message, index) => (
            <Transition
              show
              appear
              enter="transition-all transform duration-300 ease-in-out"
              enterFrom="opacity-0 translate-y-10"
              enterTo="opacity-100 translate-y-0"
              leave="transition-all duration-300 ease-in-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="w-full flex flex-col "
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <Message
                sender={message.sender}
                content={message.content}
                onReply={handleReply}
              />
            </Transition>
          ))}
          <div ref={chatEndRef} />
        </div>
      </div>
      <div className="absolute space-y-4  bottom-0 w-full bg-white p-3 pb-6 lg:max-w-4xl">
        <ChatInput
          onSubmit={handleSubmit}
          replyContent={replyContent}
          onRemoveReply={handleRemoveReply}
        />
        <div className="flex justify-between items-center w-full">
          <p className="text-xs text-gray-500">
            Demo version of Hints can make mistakes. Consider checking important
            information or upgrade to the full version.
          </p>
          <div className=" flex items-center z-10">
            <span className="w-auto px-0.5 h-5 text-xs bg-gray-300 flex justify-center items-center rounded-md mr-1">
              {osShortcut || 'Alt'}
            </span>
            <span className="text-gray-600 text-xs">shortcuts</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
