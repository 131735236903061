import {
  BuildingOffice2Icon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/20/solid';
import React from 'react';
import {
  FaAppStoreIos,
  FaSms,
  FaTelegramPlane,
  FaWhatsapp,
} from 'react-icons/fa';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function IconWrapper({
  children,
  bgColor,
  textColor,
}: {
  children: any;
  bgColor: string;
  textColor: string;
}) {
  return (
    <span
      className={classNames(
        textColor,
        bgColor,
        'inline-flex justify-center items-center rounded-lg p-1 2xl:p-3 ring-4 ring-white',
      )}
    >
      {children}
    </span>
  );
}

interface MarketingCardSectionProps {
  href: string;
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
}

const MarketingCardSection: React.FC<MarketingCardSectionProps> = ({
  href,
  icon,
  title,
  description,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className={classNames(
        'rounded-tl-lg rounded-lg',
        'group flex relative bg-white hover:bg-gray-100 px-3 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 cursor-pointer z-10 hover:border-gray-300 transition-all duration-300 ease-in-out rounded-lg border border-gray-200',
      )}
    >
      <div className="hidden sm:block">{icon}</div>
      <div className="sm:ml-4">
        <h3 className="text-sm font-semibold leading-6 text-gray-700">
          <span className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            {title}
          </span>
        </h3>
        <p className="mt-2 text-sm text-gray-500 hidden xl:block">
          {description}
        </p>
      </div>
    </a>
  );
};

export default function MarketingCard() {
  return (
    <div className="flex space-x-2">
      <MarketingCardSection
        href="https://hints.so/signup-crm"
        icon={
          <IconWrapper bgColor="bg-indigo-50" textColor="text-indigo-700">
            <BuildingOffice2Icon
              className="w-4 h-4 2xl:h-6 2xl:w-6"
              aria-hidden="true"
            />
          </IconWrapper>
        }
        title="Request full access"
        description={
          <>
            Get a call with an expert to configure your sales playbook that
            guides sales reps in chat
          </>
        }
      />
    </div>
  );
}
