/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { Select } from '../../../components/select/select';
import { useHttpsCallable } from '../../../hooks';
import { SetupComponent } from '../types';

interface Stage {
  id: string;
  name: string;
}

export const SalesforceSetup: SetupComponent<'salesforce'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  setupStyle,
}) => {
  const [stageId, setStageId] = useState<string | undefined>(
    integration.stageId,
  );
  const [stages, setStages] = useState<Stage[] | undefined>();

  const [loadPipeline] = useHttpsCallable<{ stages: Stage[] }>(
    'getCrmPipeline',
  );
  useEffect(() => {
    (async () => {
      const pipeline = await loadPipeline({
        serviceId: 'salesforce',
        id: 'default',
      });
      if (pipeline) {
        setStages(pipeline.data.stages);
      }
    })();
  }, []);

  useEffect(() => {
    setIntegrationData({ pipelineId: '', stageId });
    setIsIntegrationCanBeSaved(!!stageId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col space-y-2 mb-4 mt-6">
        <span className="text-gray-700 font-semibold">
          Choose the default stage
        </span>
        <span className="text-gray-600 font-normal">
          If you did not specify a stage in the command, the copilot will add
          the deal to this stage
        </span>
      </div>
      <Select
        value={stageId}
        onChange={setStageId}
        options={stages ?? []}
        isLoading={!stages}
        isLoaded={stages !== undefined}
        placeholder="Select a stage"
        selectFirst
        variant={setupStyle}
      />
    </div>
  );
};

export default SalesforceSetup;
