/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { integrationsArraySelector } from '@hints/client';

import { ampli } from '../../../../../ampli';
import {
  RemoteSelect,
  useHttpsCallableRemote,
} from '../../components/remote-select';
import { useAppSelector } from '../../hooks';
import { SetupComponent } from '../types';
import { FlowSection } from './FlowSection';

export const HubspotSetup: SetupComponent<'hubspot'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
}) => {
  const [pipelineId, setPipelineId] = useState<string | undefined>(
    integration.pipelineId,
  );

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const pipelinesRemote = useHttpsCallableRemote('getCrmPipelines', {
    serviceId: 'hubspot',
  });

  useEffect(() => {
    setIntegrationData({ pipelineId });
    setIsIntegrationCanBeSaved(!!pipelineId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineId]);

  return (
    <>
      <FlowSection
        completed={!!pipelineId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!pipelineId && publishButtonPressed}
        errorText="select a pipeline"
        variant={setupStyle}
      >
        <div className="flex flex-col space-y-2">
          <span className="text-gray-700 font-semibold text-lg">
            Choose the default Pipeline
          </span>
          <span className="text-gray-500 font-normal">
            Don't worry, the assistant can manage all of your Pipelines
          </span>
        </div>
      </FlowSection>
      <div className="flex flex-col">
        <RemoteSelect
          remote={pipelinesRemote}
          value={pipelineId}
          onChange={(value) => {
            setPipelineId(value);
            ampli.integrationSetupPipelineSelected({
              product: 'crmchat',
            });
          }}
          placeholder="Select pipeline"
          selectFirst
          variant={setupStyle}
        />
      </div>
    </>
  );
};

export default HubspotSetup;
