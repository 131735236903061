import axios from 'axios';

export const transcribeAudioFile = async (
  audioBlob: Blob,
  fileName: string,
  selectedLanguage?: string,
): Promise<string> => {
  const formData = new FormData();
  formData.append('file', new Blob([audioBlob]), fileName);

  if (selectedLanguage) {
    formData.append('language', selectedLanguage);
  }

  const response = await axios.post(
    'https://glacial-river-81209.herokuapp.com/upload',
    formData,
    {},
  );

  if (response.status === 200 && response) {
    return response.data?.text?.trim();
  }

  console.error('Error in transcribing audio', response);
  return '';
};
