import { TourProvider } from '@reactour/tour';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app/App';

// import { steps } from './app/pages/PlaybookAI/utils/onboardingSteps';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// TODO Add throttling

root.render(
  <StrictMode>
    {/* <TourProvider
      steps={steps}
      showBadge={false}
      showCloseButton={false}
      onClickMask={() => {}}
      showPrevNextButtons={false}
      disableKeyboardNavigation={true}
    > */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </TourProvider> */}
  </StrictMode>,
);
