/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { integrationsArraySelector } from '@hints/client';

import {
  RemoteSelect,
  useHttpsCallableRemote,
} from '../../../components/remote-select';
import { Select } from '../../../components/select/select';
import { useAppSelector } from '../../../hooks';
import { FlowSection } from '../FlowSection';
import { SetupComponent } from '../types';

export const HubspotSetup: SetupComponent<'hubspot'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
}) => {
  const [pipelineId, setPipelineId] = useState<string | undefined>(
    integration.pipelineId,
  );
  const [stageId, setStageId] = useState<string | undefined>(
    integration.stageId,
  );

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const pipelinesRemote = useHttpsCallableRemote('getCrmPipelines', {
    serviceId: 'hubspot',
  });

  useEffect(() => {
    setIntegrationData({ pipelineId });
    setIsIntegrationCanBeSaved(!!pipelineId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineId]);

  const stagesOptions = !pipelineId
    ? []
    : (pipelinesRemote.options?.find((p) => p.id === pipelineId) as any)
        ?.stages ?? [];

  return (
    <>
      <FlowSection
        completed={!!pipelineId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!pipelineId && publishButtonPressed}
        errorText="select a pipeline"
        variant={setupStyle}
      >
        <div className="flex flex-col space-y-2">
          <span className="text-gray-700 font-semibold text-lg">
            Choose the default Pipeline
          </span>
          <span className="text-gray-500 font-normal">
            Don't worry, the assistant can manage all of your Pipelines
          </span>
        </div>
      </FlowSection>
      <div className="flex flex-col">
        <RemoteSelect
          remote={pipelinesRemote}
          value={pipelineId}
          onChange={setPipelineId}
          placeholder="Select pipeline"
          selectFirst
          variant={setupStyle}
        />
        {/* <div className="flex flex-col space-y-2 mb-4 mt-10">
          <span className="text-gray-700 font-semibold text-lg">
            Choose the default stage
          </span>
          <span className="text-gray-500 font-normal">
            If you don't specify a stage, this one will be selected by default
          </span>
        </div>
        <Select
          disabled={!pipelineId}
          value={stageId}
          onChange={setStageId}
          options={stagesOptions}
          placeholder="Select stage"
          selectFirst
          variant={setupStyle}
        /> */}
      </div>
    </>
  );
};

export default HubspotSetup;
