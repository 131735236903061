import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  FolderIcon,
  HomeIcon,
  UserCircleIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { profileSelector } from '@hints/client';
import { subscriptionSelector } from '@hints/client';
import { getSubscriptionPlan } from '@hints/types';

import logo from '../../../assets/logo-circle.svg';
import { Intercom } from '../../components';
import { useAppSelector } from '../../hooks';
import MarketingCard from './components/MarketingCard';
import { classNames } from './utils/classNames';

const navigation = [
  {
    name: 'Chat',
    href: '/chat',
    route: '/chat',
    icon: ChatBubbleBottomCenterTextIcon,
    current: false,
    disabled: false,
    visibility: ['', 'productivity', 'crm', 'playbook'],
    class: 'chat',
  },
  {
    name: 'Connected CRM',
    href: '/chat/integration',
    route: '/chat/integration',
    icon: WrenchScrewdriverIcon,
    current: false,
    disabled: false,
    visibility: ['', 'productivity', 'crm', 'playbook'],
    class: 'integrations',
  },
  {
    name: 'Account',
    href: '/chat/account',
    route: '/chat/account',
    icon: UserCircleIcon,
    current: false,
    disabled: false,
    visibility: ['', 'productivity', 'crm', 'playbook'],
    class: 'settings',
  },
  // {
  //   name: 'Custom Rules',
  //   href: '/chat/rules',
  //   route: '/chat/rules',
  //   icon: DocumentTextIcon,
  //   current: true,
  //   disabled: false,
  //   visibility: ['crm', 'playbook'],
  //   class: 'rules',
  // },
];

export default function HomeChat() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector(profileSelector);
  const currentSubscription = useAppSelector(subscriptionSelector);
  const currentPlan = getSubscriptionPlan(currentSubscription) || '';
  const isAdmin = user?.role === 'admin';

  return (
    <>
      <div className="w-screen h-screen overflow-hidden">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <div className="flex h-16 shrink-0 items-center">
                        <img className="h-8 w-auto" src={logo} alt="Hints" />
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map(
                              (item) =>
                                (item.visibility.includes(currentPlan) ||
                                  isAdmin) && (
                                  <li key={item.name}>
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        location.pathname === item.route
                                          ? 'bg-gray-50 text-indigo-600'
                                          : item.disabled
                                          ? 'text-gray-400 hover:text-gray-400'
                                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                        item.class,
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                      )}
                                    >
                                      <item.icon
                                        className={classNames(
                                          location.pathname === item.route
                                            ? 'text-indigo-600'
                                            : 'text-gray-400 group-hover:text-indigo-600',
                                          'h-6 w-6 shrink-0',
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </a>
                                  </li>
                                ),
                            )}
                          </ul>
                        </li>
                        {/* <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Your teams
                          </div>
                          <ul className="-mx-2 mt-2 space-y-1">
                            <li key={user?.team}>
                              <a
                                className={classNames(
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                )}
                              >
                                <span
                                  className={classNames(
                                    user?.team
                                      ? 'text-indigo-600 border-indigo-600'
                                      : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                                  )}
                                >
                                  {user?.team?.slice(0, 1)}
                                </span>
                                <span className="truncate">{user?.team}</span>
                              </a>
                            </li>
                          </ul>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        {/* <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Your teams
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'text-indigo-600 border-indigo-600'
                                : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="#"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">Tom Cook</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div> */}

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900 hidden md:block">
            CRM Chat{' '}
            <span className="px-1 py-0.5 bg-indigo-300 ml-1 text-xs rounded-md">
              Demo
            </span>
          </div>
          <MarketingCard />
          {/* <Link to="/chat/account">
            <span className="sr-only">Your profile</span>
            <img
              className="h-9 w-9 rounded-full bg-gray-50"
              src={user?.photoUrl}
              alt=""
            />
          </Link> */}
        </div>

        <main className="w-full h-full overflow-hidden px-6 lg:px-48">
          <Outlet />
        </main>

        <Intercom />
      </div>
    </>
  );
}
