import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';

import { useIntercom } from '../contexts/IntercomContext';

export const Intercom = ({
  className,
  forceOpen,
}: {
  className?: string;
  forceOpen?: boolean;
}) => {
  const [isShow, setIsShow] = useIntercom();
  const showHide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom(isShow ? 'hide' : 'show');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (forceOpen) {
      showHide();
    }
  }, [forceOpen]);

  if (isShow) {
    return null;
  }
  return (
    <button
      type="button"
      className={`fixed z-10 right-6 bottom-7 hidden lg:block rounded-full p-3 bg-transparent border border-gray-500 hover:border-gray-700 hover:bg-gray-50 transition-all duration-300 ease-in-out ${className}`}
      onClick={showHide}
    >
      <QuestionMarkCircleIcon className="h-6 w-6 text-gray-900" />
    </button>
  );
};
