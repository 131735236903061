import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { profileSelector } from '@hints/client';
import { isCrmDestination } from '@hints/types';

import Loader from '../../components/LoaderIcon';
import {
  BackButton,
  NavigationButtons,
  NextButton,
} from '../../components/NavigationButtons';
import { useAppSelector, useHttpsCallable } from '../../hooks';
import { useConnectorInitializer } from '../../hooks/useConnectorInitializer';
import { useQueryParam } from '../../hooks/useQueryParam';
import { DestinationMetadata } from '../types';
import { StepComponent, StepComponentProps } from './StepComponent';

const ConnectionButton = ({
  metadata,
}: {
  // eslint-disable-next-line react/no-unused-prop-types
  source: string | undefined;
  metadata: DestinationMetadata;
}) => {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const isInitialized = useConnectorInitializer(metadata.connector);
  const user = useAppSelector(profileSelector);
  const [updateHubspotContact] = useHttpsCallable(
    'updateHubspotContactCallable',
  );

  const loadingMessages = [
    'connecting...',
    `talking to ${metadata.shortTitle ?? metadata.title}`,
    "don't close the tab",
  ];

  const getLoadingMessage = () => {
    setTimeout(() => {
      setLoadingMessageIndex(loadingMessageIndex + 1);
    }, 2000);
    return loadingMessages[loadingMessageIndex % loadingMessages.length];
  };

  return (
    <NextButton
      leftContent={
        !isInitialized || metadata.connector.isLoading ? (
          <Loader />
        ) : (
          <img
            src={metadata.logoUrl}
            className="w-6 h-6 mr-3"
            alt={`${metadata.title} logo`}
          />
        )
      }
      onClick={async () => {
        // it is super important to call connect before other logic
        // because otherwise popup will be blocked by browser
        metadata.connector.connect();

        updateHubspotContact({
          email: user.email,
          properties: [
            {
              property: 'integrations',
              value: metadata.key,
            },
          ],
        }).catch((e) => console.error('Unable to update hubspot contact', e));
      }}
      disabled={!isInitialized || metadata.connector.isLoading}
      text={
        metadata.connector.isLoading
          ? getLoadingMessage()
          : metadata.authButtonText ??
            `Login to ${metadata.shortTitle ?? metadata.title}`
      }
    />
  );
};

export const DestinationAuthStep: StepComponent = ({
  navigation,
  metadata,
  source,
}: StepComponentProps) => {
  const navigate = useNavigate();
  const isFromDestination = !!useQueryParam('destinationSelected');

  useEffect(() => {
    if (metadata.connector.isConnected) {
      navigation.next?.();
    }
  }, [navigation, metadata.connector.isConnected]);

  return (
    <div className="h-full flex flex-col justify-between space-y-4">
      <div className="h-full overflow-y-auto flex flex-col space-y-4 mb-6">
        {metadata.instructions}
        {metadata.connector.error ? (
          <p>{metadata.connector.error.message}</p>
        ) : null}
      </div>
      <NavigationButtons>
        <BackButton onClick={() => navigate('/')} />
        <ConnectionButton source={source} metadata={metadata} />
      </NavigationButtons>
    </div>
  );
};
