import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import HubspotIcon from '@hints/shared/icons/integrations/Hubspot.svg';

import HubspotTutorialGif from '../../../../../assets/TG-Hubspot.png';
import { useAppDispatch, useAppSelector, useHttpsCallable } from '../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { HubspotSetup } from '../setups/HubspotSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const CLIENT_ID = 'ae16a799-9c2e-4b87-9e2b-f77a92fb2408';
const SCOPES = [
  'crm.objects.owners.read',
  'crm.objects.contacts.read',
  'crm.objects.contacts.write',
  'crm.schemas.contacts.read',
  'crm.objects.deals.read',
  'crm.objects.deals.write',
  'crm.schemas.deals.read',
  'crm.objects.companies.read',
  'crm.objects.companies.write',
  'crm.schemas.companies.read',
].join('%20');
const AUTH_URL = `https://app-eu1.hubspot.com/oauth/authorize?client_id=${CLIENT_ID}&scope=${SCOPES}`;

const useHubspotConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizeHubspot] = useHttpsCallable('authorizeHubspot');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl: AUTH_URL,
    onComplete: async (code, redirectUri) => {
      //@ts-ignore
      await authorizeHubspot({ code, redirectUri });
    },
  });

  const disconnect = () =>
    dispatch(
      updateProfile({
        hubspot: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected: !!user.hubspot?.accessToken,
    connect: startAuth,
    isLoading,
    disconnect,
    error,
  };
};

export const hubspot: OutputIntegrationMetadata<'hubspot'> = {
  title: 'Hubspot',
  logoUrl: HubspotIcon,
  connectorHook: useHubspotConnector,
  defaultTagName: 'hubspot',
  setupComponent: HubspotSetup,
  tutorial: {
    gif: (
      <img
        src={HubspotTutorialGif}
        alt=""
        className="w-full h-[160px] rounded-lg"
      />
    ),
    examples: [
      '✅ Now you can create and update your deals. Just tell the Assistant what you want in your own words. See examples below:',
      '🤖 Create a new deal Microsoft Bill Gates bill@microsoft.com',
      '🤖 Remind me to follow up with Bill Gates from Microsoft on March 17th',
      '🤖 Call John from IBM tomorrow',
      '🤖 Add a note to IBM: Meeting went great. I’m waiting for the final approval',
      '👥 To use this bot with your team, add it to your group and use #pipedrive in your requests. Example: Move IBM to Demo Scheduled #hubspot',
    ],
  },
  instructions: (
    <></>
    // <div className="space-y-4">
    //   <p>
    //     Make sure you are logged in to HubSpot on your device browser before
    //     continuing.
    //   </p>
    //   <p>
    //     Then click the “<b>Login to Hubspot</b>” button below and enter your
    //     account
    //   </p>
    //   <div>
    //     <span className="text-gray-700 italic text-sm">
    //       ⚠️ Don't forget to choose Hubspot account or team to sync with Hints
    //     </span>
    //     <img
    //       src={HubspotStep2}
    //       alt="step2"
    //       className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
    //     />
    //   </div>
    // </div>
  ),
};
